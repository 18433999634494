import * as React from 'react';
import './user-verifier.css';
import { IdentificationRegistry } from '../../services/identification-registry';
import { MIDSDKBiometricMethod, VerificationObserver } from '../../../../assets/js/mobbid-sdk-core-1.13.3.js'
import { VoiceUIObserver } from '../../../../assets/js/mobbid-sdk-voice-1.13.3.js'
import { Translation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

export class UserVerifier extends React.Component<any, any> {
    
    private registry: IdentificationRegistry;
    private voiceUIObserver = new VoiceUIObserver();

    constructor(props: any) {
        super(props);
        this.state = {
            initialized: false,
            detecting: false,
            userIdConfirmed: false,
            verified: false,
            unverified: false,
            timeout: false,
            error: undefined,
            eventSent: false,
            user: '',
            userId: '',
        };
        this.registry = new IdentificationRegistry(this.props.registrationURL);        
    }

    onUserIdConfirmed(): void {        
        const initContainer = this.props.midSDK.setContainer(MIDSDKBiometricMethod.VOICE, document.getElementById('voiceContainer'), this.props.language, this.voiceUIObserver);
        initContainer.then(result => {
            this.setState({ ...this.state, initialized: true });
            const verificationObserver = new VerificationObserver();
            this.props.midSDK.verify(MIDSDKBiometricMethod.VOICE, this.state.userId, verificationObserver);
            verificationObserver
                .errors()
                .subscribe(error => {
                    this.onError(error);                    
                });
            verificationObserver
                .verified()
                .subscribe(result => {                    
                    this.onUserVerified(result);
                });
            verificationObserver
                .notVerified()
                .subscribe(result => {
                    this.onUserNotVerified(result);
                });
            this.voiceUIObserver
                .progress()
                .subscribe(progress => { 
                    this.setState({ ...this.state, error: undefined });
                    if (progress >= 1) {           
                        this.setState({ ...this.state, detecting: true });
                    }
            });
            this.voiceUIObserver
                .errors()
                .subscribe(error => {   
                    this.onError(error);
            });
        });
    }

    onError(error: string): void {
        console.log("ERROR: " + error);
        if (error === 'TIMEOUT') {
            this.setState({ ...this.state, timeout: true, detecting: false });
            this.props.onUserNotVerified();
        } else {
            this.setState({ ...this.state, error: error, detecting: false });
        }
    }

    onUserVerified(result: any): void {
        console.log("VERIFIED");
        this.setState({ ...this.state, verified: true, detecting: false });
        const id = result.userId;
        let user = id;
        this.registry
            .get(id)
            .then((result: any) => {
                console.log(result);
                if ('name' in result) {                    
                    user = result.name;
                }
            })
            .catch(() => {
                // noop
            })
            .finally(() => {
                this.setState({ ...this.state, user: user, userId: id, verified: true });
                this.props.onSuccess(result.score);
            });
    }

    onUserNotVerified(result: any): void {
        console.log("NOT VERIFIED");
        this.setState({ ...this.state, unverified: true, detecting: false });
        const id = result.userId;
        let user = id;
        this.registry
            .get(id)
            .then((result: any) => {
                console.log(result);
                if ('name' in result) {                    
                    user = result.name;
                }
            })
            .catch(() => {
                // noop
            })
            .finally(() => {
                this.setState({ ...this.state, user: user, userId: id, verified: false });
                this.props.onUserNotVerified(result.score);
            });
    }

    confirmEventSent(): void {
        this.setState({ ...this.state, eventSent: true });
    }

    handleInputChange = (event: any) => {
        const value = event.target.value;
        this.setState({ ...this.state, userId: value });
    };

    handleSubmit = (event: any) => {
        this.registry
            .get(this.state.userId)
            .then((result: any) => {                
                if (result['email'] === this.state.userId) {
                    this.setState({ ...this.state, userIdConfirmed: true, unknownUser: false, user: result['name'] });
                    this.onUserIdConfirmed();
                } else {                    
                    this.setState({ ...this.state, unknownUser: true });
                    this.props.onUnknownUser();
                }
            })
            .catch(() => {
                // noop
            });        
        event.preventDefault();
    };

    render(): any {

        return (
            <div>
                {this.state.userIdConfirmed === false ? (
                <Translation>
                    {t => (
                        <div>
                            <form onSubmit={this.handleSubmit}>
                                <fieldset>
                                    <legend>{t('Insert your e-mail')}</legend>                                    
                                    <input
                                        type="email"
                                        name="email"
                                        value={this.state.emailFormValue}
                                        placeholder="user@email.com"
                                        onChange={this.handleInputChange}
                                        required
                                    ></input>
                                    <input
                                        type="submit"
                                        value={String(t('Next'))}
                                        disabled={this.state.registering}
                                    ></input>
                                </fieldset>
                            </form>
                            <p className="status">
                                <Translation>
                                    {t => (
                                        <div>
                                            <CSSTransition in={this.state.unknownUser} timeout={1000} classNames="message">
                                                <span className="message">{t('User does not exists')}...</span>
                                            </CSSTransition>
                                        </div>
                                    )}
                                </Translation>
                            </p>
                        </div>
                    )}
                </Translation>            
                ) :
                (<div id="stepContainer">
                    <div id="voiceContainer"></div>
                    <div className="status">
                        <Translation>
                            {t => (
                                <div>
                                    <CSSTransition in={this.state.error} timeout={1000} classNames="message">
                                        <span className="message">{t(this.state.error)}</span>
                                    </CSSTransition>
                                    <CSSTransition in={this.state.timeout} timeout={1000} classNames="message">
                                        <span className="message">{t('Timeout while detecting voice.')}</span>
                                    </CSSTransition>                                
                                    <CSSTransition in={this.state.unverified} timeout={1000} classNames="message">
                                        <span className="message">{t('User not verified.').replace(" ", " " + this.state.user + " ")}</span>
                                    </CSSTransition>
                                    <CSSTransition in={this.state.verified} timeout={1000} classNames="message">
                                        <span className="message">{t('User verified.').replace(" ", " " + this.state.user + " ")}</span>
                                    </CSSTransition>
                                    <CSSTransition in={this.state.initialized && this.state.detecting} timeout={0} classNames="message">
                                        <span className="message">{t('Verifying user.') + " " + t('Please wait...')}</span>
                                    </CSSTransition>
                                    <CSSTransition in={this.state.initialized && this.state.detecting} timeout={0} classNames="spinner">
                                        <img className="spinner" src="assets/img/spinner.png" alt="spinner"/>
                                    </CSSTransition>
                                </div>
                            )}
                        </Translation>
                    </div>
                </div>)}
            </div>
        );
    }
}
